const campaignColumns = [
  {
    key: 'Crid',
    label: 'CRID',
    show: true,
    headerTitle: 'CRID',
    sortable: true,
    // thStyle: 'width:90px',
    tdClass: 'text-left',
    summed: false,
  },
  {
    key: 'GeoedgeId',
    label: 'Geoedge ID',
    show: true,
    headerTitle: 'Geoedge ID',
    sortable: true,
    // thStyle: 'width:110px',
    tdClass: 'text-left',
    summed: false,
  },
  {
    key: 'TriggerTypeId',
    label: 'Trigger Type ID',
    show: true,
    headerTitle: 'Trigger Type ID',
    tdClass: 'text-right',
    thStyle: 'width:120px',
    sortable: true,
    summed: false,
  },
  {
    key: 'TriggerType',
    label: 'Trigger Type',
    show: true,
    headerTitle: 'Trigger Type',
    sortable: true,
    // thStyle: 'width:130px',
    summed: false,
  },
  {
    key: 'TriggerMetadata',
    label: 'Trigger Metadata',
    show: true,
    headerTitle: 'Trigger Metadata',
    sortable: true,
    summed: false,
  },
  {
    key: 'TimeUpdated',
    label: 'Time Updated',
    show: true,
    headerTitle: 'Time Updated',
    sortable: true,
    sortDirection: 'desc',
    thStyle: 'width:130px',
    // tdClass: 'text-right',
    summed: false,
  },
]
export default campaignColumns
